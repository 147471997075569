<template>
    <div>
        <div class="container">
            <div class="title">{{msg}}</div>
            <form enctype="multipart/form-data" novalidate>
                <div class="upload-btn-wrapper dragndrop">
                    <div class="btn">Drag a file here or browse a file to upload</div>
                    <input name="File" type="file" v-on:change="saveFile" multiple>
                </div>
                <div v-if="file.name" class="file-info">
                    <div class="filename">{{file.name}}</div>
                    <div class="filesize">{{file.size | prettyBytes}}</div>
                    <div class="fileprogress">{{file.progress}} %</div>
                    <progress class="progress" v-bind:value=file.progress max="100"></progress>
                </div>
                <div v-if="file.text">
                    <div class="link"><a v-bind:href="file.url" target="_blank">{{file.text}}</a></div>
                    <div>
                        <button v-on:click.prevent class="button" 
                                                   v-clipboard="file.url">Copy link</button>
                    </div>
                </div>
                <button v-if="file.allowUpload" type="submit" class="button" 
                                                              v-on:click="postNow">Upload</button>
            </form>
        </div>
    </div>
</template>

<script>
import File from '../services/file.js';

export default {
    name: 'Shr',
    data () {
        return {
            file: new File(),
            msg: 'Share your files'
        }
    },
    methods: {
        clear() {
            this.msg = 'Share your files'
            this.file.clear();
        },
        saveFile(event){
            if( ! this.file.getFileFromEvent(event))
                this.msg = 'File is too big!'
        },
        postNow(event) {
            event.preventDefault();
            this.file.upload(event, rtn => {
                if(rtn)
                    this.msg = 'File has been uploaded'
                else
                    this.msg = 'Something went wrong'
            })
        }
    }
}
</script>

<style scoped>

.btn {
	font-size: 1rem;
}

.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    height: 8rem;
}


.upload-btn-wrapper input[type=file] {
    font-size: 20rem;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

.dragndrop {
	border: 2px dashed #ccc;
	padding: 2rem;
	margin-bottom: 2rem;
}

.progress {
    height: 0.2rem;
	width: 100%;
	grid-area: progress;
}

.link {
    margin-top: 2rem;
    margin-bottom: 1rem;
	text-align: center;
}

.file-info {
	display: grid;
	grid-template-rows: auto;
	grid-template-columns: 5fr 2fr 2fr;
	grid-template-areas:
	"filename filesize fileprogress"
	"progress progress progress";
	width: 100%;
	font-size: 0.9rem;
	margin-bottom: 0.5rem;
}

.filename {
	grid-area: filename;
    text-align: left;
}

.filesize {
	grid-area: filesize;
	text-align: right;
}

.fileprogress {
	grid-area: fileprogress;
	justify-self: end;
	text-align: right;
}


</style>
