import Vue from 'vue'
import App from './App'
import router from './router'

const VueClipboards = require('vue2-clipboards')

Vue.use(VueClipboards);

Vue.config.productionTip = false

Vue.filter('prettyBytes', function (num) {
    num = Number(num);
    if (isNaN(num)) {
        throw new TypeError('Expected a number');
    }

    var neg = num < 0;
    var units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    if (neg) {
        num = -num;
    }

    if (num < 1) {
        return (neg ? '-' : '') + num + ' B';
    }

    var exponent = Math.min(Math.floor(Math.log(num) / Math.log(1000)), units.length - 1);
    num = (num / Math.pow(1000, exponent)).toFixed(2) * 1;
    var unit = units[exponent];

    return (neg ? '-' : '') + num + ' ' + unit;
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
