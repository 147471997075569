import axios from 'axios';

export default class File {

    constructor(options) {
        const defaults = {
            name: '',
            url: '',
            text: '',
            progress: 0,
            size: 0,
            allowUpload: false
        }
        const populated = Object.assign(defaults, options)
        for (const key in populated) {
            if (populated.hasOwnProperty(key)){
                this[key] = populated[key];
            }
        this.formData
        }
    }

    clear() {
        this.name = ''
        this.url = ''
        this.text =''
        this.progress = 0
        this.size = 0
        this.allowUpload = false
    }

    getFileFromEvent(event) {
        this.clear()
        let formData = new FormData()
        let filesize = 0;
        let count = 0;

        for (let file of event.target.files) {
            formData.append('File', file)
            filesize+=file.size
            count++;
        }

        this.formData = formData
        this.size = filesize

        if (filesize > 4* 1024 * 1024 * 1024) {
            this.allowUpload = false
            return false 
        }

        if (count > 1) 
            this.name = 'multiple files ['+count+']'
        else
            this.name = event.target.files[0].name
        this.allowUpload = true
        return true
    }

    upload(event, cb)
    {
        this.allowUpload = false;
        let config = {
            onUploadProgress: progressEvent => {
                let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total)
                this.progress = percentCompleted
            }
        }
        axios.post("/upload", this.formData, config)
        .then(response => {
            this.text = response.data.text
            this.url = response.data.url
            this.name = ''
            cb(true)
            
        })
        .catch(e => {
            console.log(e)
            this.url = ''
            this.name = ''
            cb(false)
        });
    }
}
