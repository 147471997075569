
<template>
    <div class="footer">
        <div class="version">v{{ver}}</div>
    <div class="rights">(C) 2020 Tomas Papan. Fork me on <a href="https://gitlab.papan.sk/morpheus/shr">Gitlab</a></div>
    </div>
</template>

<script>

import {version} from '../../package.json'

export default {
    name: 'Footer',
    data () {
        return {
            ver: version
        }
    },
    methods: {
    }
}
</script>

<style scoped>

.footer {
    display: grid;
    grid-template-columns: 8fr 2fr;
    grid-template-rows: ;
    grid-template-areas:
        "rights version";
    width: 100%;
    height: 100%;
    font-size: 0.8rem;
    align-items: end;
    justify-items: start;
    font-weight: 300;
    max-width: var(--max-width);
    margin: 0 auto;
}

.rights {
    grid-area: rights;
    padding: 1rem;
}

.version {
    grid-area: version;
    justify-self: end;
    padding: 1rem;
}

</style>
