import Vue from 'vue'
import Router from 'vue-router'
import Shr from '@/components/shr'

Vue.use(Router)

export default new Router({
    mode: 'history',
	routes: [
		{
			path: '/',
			name: 'Shr',
			component: Shr
		},
		{
            path: '/uploads/:id',
			name: 'Upload',
            component: () => import(/* webpackChunkName: "upload" */ './components/upload.vue')
		}
	]
})
