<template>
    <div id="app">
        <div id="header"><Header/></div>
        <router-view id="main"/>
        <div id="footer"><Footer/></div>
    </div>
</template>

<script>

import Header from '@/components/header'
import Footer from '@/components/footer'

export default {
    name: 'app',
    components : {
        Header: Header,
        Footer: Footer
    }  
}

</script>

<style>
:root {
    --font: black;
    --background: white;
    --second: #F3F5F9;
    --third: #0971CE;
    --simple: white;
    --max-width: 800px;
}

html, body {
    background: var(--background);
    color: var(--font);
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    margin: 0;
    width: 100%;
    height: 100%;
    font-size: 14px;
}

a:hover, a:visited, a:link, a:active {
	text-decoration: none;
    color: var(--third);
    font-weight: 500;
}

#app {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 4fr 7fr 1fr;
    grid-template-areas:
        "header"
        "main"
        "footer";
    justify-items: center;
    height: 100%;
}

#header {
    grid-area: header;
    text-align: center;
    width: 100%;
}

#main {
    grid-area: main;
    text-align: center;
    width: 100%;
}

#footer {
    grid-area: footer;
    background: var(--second);
    width: 100%;
    text-align: center;
}

.container {
    padding: 0.5rem;
}

.title {
    font-weight: 500;
    font-size: 1rem;
    text-align: center;
    padding-bottom: 2rem;
    color: var(--third);

}

button {
    display: inline-block;
    border: none;
    padding: 1rem 2rem;
    margin: 0;
    text-decoration: none;
    background: var(--third);
    color: white;
    font-size: 1rem;
    line-height: 1;
    cursor: pointer;
    text-align: center;
    transition: background 250ms ease-in-out, transform 150ms ease;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
}

button:hover,
button:focus {
    background: #0053ba;
}

button:focus {
    outline: 1px solid #fff;
    outline-offset: -4px;
}

button:active {
    transform: scale(0.99);
}

@media (min-width: 600px) {
    html {
        font-size: 16px;
    }
    #app { 
        display: grid;
        grid-template-columns: 10% 40% 40% 10%;
        grid-template-rows: 5fr 14fr 1fr;
        grid-template-areas:
        "header header header header"
        ". main main ."
        "footer footer footer footer";
        justify-items: center;
        height: 100%;
    }

    #main {
        max-width: var(--max-width);
    }
}

</style>
