<template>
    <div class="header" v-on:click="home">
        <img class="center" src="/cloud.png" alt="cloud"/>
        <div class="logo">Instashare</div>
    </div>
</template>

<script>
export default {
    name: 'Header',
    data () {
        return {
        }
    },
    methods: {
        home() {
            this.$router.push({name: 'Shr'})
        }
    }
}
</script>

<style scoped>

.header {
	padding-top: 3rem;
    cursor: pointer;

}

.logo {
    font-size:3rem;
    color:var(--third);
    font-weight: 900;
}

.center {
    max-width: 10rem;
    text-align: center;
    margin: 0 auto;
    display: block;
    padding: 0;
}



</style>
